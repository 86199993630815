<template>
  <div class="main-body">
    <!-- 开屏动画 -->
    <div id="loader-wrapper">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>

    <div class="main-content">
      <!-- 轮播图 -->
      <div class="banner">
        <el-carousel height="100vh" :interval="6000">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.slideshowImg" alt="" class="banner-pic" />
            <div class="banner-textPic" v-html="item.text"></div>
            <img class="logo" :src="item.logo" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 备案号 -->
      <!-- <div class="footer">
        西安博垦特建材科技有限公司 Copyright ©2021 陕ICP备2021006183号-1
        技术支持：西安思沃科技有限公司
      </div> -->
      <div
        class="language-wrap"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
      >
        <!-- 切换语种 -->
        <div class="change-language">
          <img :src="lenguagePic" alt="" />
          <div class="cl-text">
            <div>{{ this.language }}</div>
            <i class="el-icon-caret-bottom"></i>
          </div>
        </div>
        <!-- 选择语种弹窗 -->
        <transition name="fade">
          <div class="select-language" v-show="isShow">
            <div class="sl-box" @click="setLang('CN')">
              <img src="../../assets/images/lang_2.png" alt="" />
              <div>中文</div>
            </div>
            <div class="sl-box" @click="setLang('EN')">
              <img src="../../assets/images/lang_4.png" alt="" />
              <div>English</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"; //引入axios
export default {
  data() {
    return {
      bannerList: [],
      // language: "中文",
      // lenguagePic: "../../assets/images/展开更多.png",
      isShow: false,
    };
  },
  computed: {
    lenguagePic() {
      const lang = localStorage.getItem("lang");
      if (lang === "CN") {
        return "http://edu.siwonet.com:8099/lang_CN.png";
      }
      return "http://edu.siwonet.com:8099/lang_EN.png";
    },
    language() {
      const lang = localStorage.getItem("lang");
      if (lang === "CN") {
        return "中文";
      }
      return "English";
    },
  },
  methods: {
    // selectChinese() {
    //   if (this.language != "中文") {
    //     this.language = "中文";
    //     this.lenguagePic = "../../assets/images/lang_2.png";
    //   }
    // },
    // selectEnglish() {
    //   if (this.language != "英文") {
    //     this.language = "英文";
    //     this.lenguagePic = "../../assets/images/lang_4.png";
    //   }
    // },
    setLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    mouseOver() {
      this.isShow = true;
      // this.active = "bottom: 0;transition: 1s;";
    },
    mouseLeave() {
      this.isShow = false;
    },
    querySlideshowByChecked() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("http://edu.siwonet.com:8099/querySlideshowByChecked", {
          params: {
            language: lang,
            checked: 1,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.bannerList = res.data.data;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  mounted() {
    this.querySlideshowByChecked();
    setTimeout(() => {
      document.body.classList.add("full");
      document.body.classList.add("loaded");
      setTimeout(() => {
        document.getElementById("loader-wrapper").remove();
      }, 1000);
    }, 500);
  },
};
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0.8);
}
.main-body {
  width: 100%;
  // height: 100%;
  .main-content {
    width: 100%;
    height: 100%;
    position: relative;
    // 轮播图
    .banner {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: rgba(68, 68, 68);
      .banner-pic {
        min-width: 100%;
        height: 100%;
      }
      .banner-textPic {
        font-size: 20px;
        color: rgb(255, 255, 255);
        position: absolute;
        left: 20%;
        top: 52%;
        z-index: 99;
        transition: 1s;
      }
      .logo {
        position: absolute;
        left: 20%;
        top: 20%;
        z-index: 99;
        width: 540px;
        transition: 1s;
      }
    }
    // 导航
    .nav {
      width: 200px;
      height: 100%;
      background-color: #d80930;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: #fff;
        font-size: 18px;
        margin: 40px auto;
        display: block;
        position: relative;
        padding: 10px;
      }
    }
    // 新闻导航
    .banner-news {
      width: 70px;
      position: absolute;
      right: 165px;
      top: 25%;
      z-index: 100;
      .bn-theme {
        font-size: 15px;
        color: #d80930;
        width: 39px;
        height: 60px;
        text-align: center;
        margin: 0 auto;
        border-bottom: 1px solid #dadada;
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        z-index: 101;
        letter-spacing: 3px;
      }
      .bn-pic {
        background-color: #fff;
        width: 100%;
        height: 100%;
      }
      .bn-symbols {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        padding-top: 100px;
        padding-left: 31px;
        box-sizing: border-box;
        font-size: 14px;
        color: #787a81;
      }
      .bn-text {
        width: 20px;
        height: 95%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        padding-top: 120px;
        box-sizing: border-box;
        font-size: 14px;
        color: #787a81;
        overflow: hidden;
        text-align: center;
      }
    }
    .footer {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      position: absolute;
      right: 250px;
      bottom: 50px;
      z-index: 99;
    }
    .language-wrap {
      position: absolute;
      right: 11%;
      top: 14px;
      z-index: 99;
      min-height: 40px;
      width: 130px;
    }
    .change-language {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 40px;
        height: 40px;
      }
      .cl-text {
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgb(255, 255, 255);
        padding: 0 10px;
        box-sizing: border-box;
        font-weight: 600;
        cursor: pointer;
        text-shadow: 0px 1px 1px #474747;
        font-size: 18px;
      }
    }
    .select-language {
      width: 200px;
      // position: absolute;
      // right: 230px;
      // top: 100px;
      // z-index: 99;
      margin-top: 50px;
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      .sl-box {
        display: flex;
        color: rgb(114, 114, 114);
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        img {
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
      }
      .sl-box:hover {
        background-color: #d80930;
        transition: 0.3s;
        color: rgb(255, 255, 255);
      }
    }
  }
}

// 开屏动画
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 1 */
  border-top-color: #fff;
  -webkit-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 1001;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 2 */
  border-top-color: #fff;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  -o-animation: spin 3s linear infinite;
  -ms-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  /* COLOR 3 */
  -moz-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  -ms-animation: spin 1.5s linear infinite;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: rgb(163, 31, 38);
  z-index: 1000;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}

.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  /* visibility: hidden; */
  /* -webkit-transform: translateY(-100%); */
  -ms-transform: translateY(-100%);
  /* transform: translateY(-100%); */
  /* -webkit-transition: all 0.3s 1s ease-out; */
  /* transition: all 0.3s 1s ease-out; */
}
</style>
